var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { Emit } from "vue-property-decorator";
import { SensitiveWordService } from "~/services/manage-service/sensitive-word.service";
import SvgIcon from "~/components/common/svg-icon.vue";
var AddWordConfiguration = /** @class */ (function (_super) {
    __extends(AddWordConfiguration, _super);
    function AddWordConfiguration() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.principalList = [];
        // 提示词
        _this.promptWord = "";
        // 严重词
        _this.seriousWord = "";
        _this.loading = {
            state: false
        };
        _this.rules = {
            principalId: [
                { required: true, message: "请选择委托方" }
            ]
        };
        _this.model = {
            principalId: "",
            principalName: "",
            promptWords: [],
            seriousWords: []
        };
        return _this;
    }
    AddWordConfiguration.prototype.emitClose = function () {
        this.model.principalId = "",
            this.model.principalName = "",
            this.model.promptWords = [],
            this.model.seriousWords = [];
    };
    AddWordConfiguration.prototype.emitSuccess = function () { };
    // 词汇添加
    AddWordConfiguration.prototype.appendClick = function (type) {
        if (type === 1) {
            if (!this.promptWord || this.promptWord.trim() === "") {
                this.promptWord = "";
                this.$message("提示词为空");
                return;
            }
            if (this.model.promptWords.includes(this.promptWord)) {
                this.$message("已存在该词条");
                return;
            }
            this.model.promptWords.push(this.promptWord);
            this.promptWord = "";
        }
        if (type === 2) {
            if (!this.seriousWord || this.seriousWord.trim() === "") {
                this.seriousWord = "";
                this.$message("严重词为空");
                return;
            }
            if (this.model.seriousWords.includes(this.seriousWord)) {
                this.$message("已存在该词条");
                return;
            }
            this.model.seriousWords.push(this.seriousWord);
            this.seriousWord = "";
        }
    };
    AddWordConfiguration.prototype.submit = function () {
        var _this = this;
        var visitForm = this.$refs['add-word-configuration'];
        visitForm.validate(function (valid) {
            if (!valid)
                return;
            if (_this.model.promptWords.length == 0 && _this.model.seriousWords.length == 0) {
                _this.$message.warning("提示词与敏感词都为空，请输入要设置的信息");
                return;
            }
            var principal = _this.principalList.filter(function (e) { return e.id === _this.model.principalId; });
            _this.model.principalName = principal[0].name;
            _this.loading.state = true;
            _this.sensitiveWordService.addSensitiveWord(_this.model, _this.loading).subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.emitSuccess();
                _this.emitClose();
            });
        });
    };
    AddWordConfiguration.prototype.removeTag = function (data, flag) {
        if (flag === 0) {
            var index = this.model.promptWords.indexOf(data);
            if (index > -1) {
                this.model.promptWords.splice(index, 1);
            }
        }
        else {
            var index = this.model.seriousWords.indexOf(data);
            if (index > -1) {
                this.model.seriousWords.splice(index, 1);
            }
        }
    };
    AddWordConfiguration.prototype.refresh = function () {
        var _this = this;
        this.loading.state = true;
        this.sensitiveWordService.getPrincipalNoWordConfig(this.loading).subscribe(function (data) {
            _this.principalList = data;
        });
    };
    __decorate([
        Dependencies(SensitiveWordService)
    ], AddWordConfiguration.prototype, "sensitiveWordService", void 0);
    __decorate([
        Emit("close")
    ], AddWordConfiguration.prototype, "emitClose", null);
    __decorate([
        Emit('success')
    ], AddWordConfiguration.prototype, "emitSuccess", null);
    AddWordConfiguration = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                SvgIcon: SvgIcon
            }
        })
    ], AddWordConfiguration);
    return AddWordConfiguration;
}(Vue));
export default AddWordConfiguration;
